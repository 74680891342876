export const data = [
    {
        "imageUrl": "/projectphoto/1.jpeg"
    },
    {
        "imageUrl": "/projectphoto/2.jpg"
    },
    {
        "imageUrl": "/projectphoto/7.jpg"
    },
    {
        "imageUrl": "/projectphoto/8.jpeg"
    }
]