import React from 'react';
import './App.css';
import Nav from './Nav';
import Footer from './Footer';
function App() {
  return (
    <>
    <Nav/>
    <Footer/>
    </>
  );
}

export default App;
