import React from "react";
import  Contact  from './Contact';
import Banner from './Banner';
import RadiusInvertor from './RadiusInvertor';
function Home(){
    return (
    <>
<Banner/>
<Contact/>
<RadiusInvertor/>
</>
    );
}
export default Home;